const apiUrl = 'https://ama-api.toubiana.dev/';

export default class Utils {
    static postQuestion(questionId: string, questionInput: string, onSuccess: (response: any) => void, onFailure: (error: any) => void) {

        var payload = {
            question: questionInput
        }

        fetch(apiUrl + '/api/questions/' + questionId, { method: "POST", body: JSON.stringify(payload) })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then(onSuccess)
            .catch(onFailure);
    }

    static postLike(meetingId: string, questionId: string, isLiked: boolean, onSuccess: (response: any) => void, onFailure: (error: any) => void) {
        const cancelParameter = isLiked ? '?cancel=true' : '';

        fetch(apiUrl + '/api/questions/' + meetingId + "/vote/" + questionId + cancelParameter, { method: "POST" })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then(onSuccess)
            .catch(onFailure);
    };

    static postRead(meetingId: string, questionId: string, onSuccess: (response: any) => void, onFailure: (error: any) => void) {
        fetch(apiUrl + '/api/questions/' + meetingId + "/read/" + questionId, { method: "POST" })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then(onSuccess)
            .catch(onFailure);
    };

    static GetQuestions(meetingId: string, onSuccess: (response: any) => void, onFailure: (error: any) => void) {
        fetch(apiUrl + '/api/questions/' + meetingId)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then(onSuccess)
            .catch(onFailure);
    }
}
