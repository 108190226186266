import React from 'react';
import { IIconProps, IconButton } from 'office-ui-fabric-react';


const likeNotSelectedIcon: IIconProps = { iconName: 'Like' }
const likeSelectedIcon: IIconProps = { iconName: 'LikeSolid' }

export interface IToggleLikeButtonProps {
    id: string;
    meetingId: string;
    liked: boolean;
    onLikeClicked: (meetingId: string, questionId: string, isLiked: boolean) => void;
}

export const ToggleLikeButton: React.FunctionComponent<IToggleLikeButtonProps> = props => {
    const { id, meetingId, liked, onLikeClicked } = props;

    return (
        <IconButton
            toggle
            iconProps={liked ? likeSelectedIcon : likeNotSelectedIcon}
            onClick={() => onLikeClicked(meetingId, id, liked)}
            allowDisabledFocus
        />
    );
};
