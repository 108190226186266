import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import CreatePage from './Pages/CreatePage';
import QuestionsPage from './Pages/QuestionsPage';
import PresentationPage from './Pages/PresentationPage';
import { initializeIcons } from '@uifabric/icons';
import './styles/App.css';

initializeIcons();

export const App: React.FunctionComponent = () => {
  return (
    <Router>
      <div style={{height: "100%"}}>
        <Switch>
          <Route path="/:id/presentation" component={PresentationPage} />
          <Route path="/:id" component={QuestionsPage} />
          <Route path="/" component={CreatePage}/>
        </Switch>
      </div>
    </Router>
  );
};
