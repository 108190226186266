import React from 'react';
import { Text, Icon } from 'office-ui-fabric-react';

import './../styles/PresentationPage.css'

interface IPresentationRowProps {
    question: string;
    votes: number;
    onReadClicked: () => void;
    useSmallText: boolean;
}

const PresentationRow: React.FunctionComponent<IPresentationRowProps> = (props) => {
    const { question, votes, useSmallText, onReadClicked } = props;

    const textSize = useSmallText ? 'xLarge' : 'superLarge';
    return (
        <div className="ms-depth-8 questionPresentationContainer">
            <Text variant={textSize} className="questionText">
                {question}
            </Text>
            <div className="likesContainer likesFullScreenContainer">
                <Icon iconName="LikeSolid" className="likeIcon" />
                <div className="likesItem likesItemFullScreen">
                    <Text variant={textSize}>
                        {votes}
                    </Text>
                </div>
            </div>
            <Icon onClick={onReadClicked} iconName="Accept" className="readIcon" />
        </div>
    );
};

export default PresentationRow;
