import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Stack, Text, Spinner, Image, Icon } from 'office-ui-fabric-react';
import logo from './../images/logo-fullsize.png';
import './../styles/PresentationPage.css';
import 'office-ui-fabric-react/dist/css/fabric.css';
import Utils from '../utilities/apiUtilities';
import PresentationRow from '../Components/PresentationRow';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';

interface MatchParams {
    id: string;
}

interface IPresentationPageProps extends RouteComponentProps<MatchParams> {
}

interface Question {
    id: string;
    question: string;
    votes: number;
    createdAt: string;
    read: boolean;
}

interface IPresentationPageState {
    isLoading: boolean;
    questions: Array<Question>
    error: Error | null
    likedQuestions: { [id: string]: boolean }
    windowWidth: number
}

class PresentationPage extends React.Component<IPresentationPageProps, IPresentationPageState> {

    intervalId: NodeJS.Timeout | null;

    constructor(props: IPresentationPageProps) {
        super(props);

        this.state = {
            questions: [],
            isLoading: false,
            error: null,
            likedQuestions: {},
            windowWidth: 0,
        };
        this.intervalId = null;
    }

    componentDidMount() {
        document.title = 'AMA #' + this.props.match.params.id
        this.updateDimensions();
        this.setState({ isLoading: true });
        this.refreshQuestions();
        this.intervalId = setInterval(this.refreshQuestions.bind(this), 10000);
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        if (this.intervalId != null) {
            clearInterval(this.intervalId);
        }
    }

    refreshQuestions() {
        const { id } = this.props.match.params;

        const onSuccess = (response: { questions: Question[] }) => this.setState({ questions: response.questions, isLoading: false, error: null });

        Utils.GetQuestions(id, onSuccess, error => this.setState({ error, isLoading: false }));
    }

    onReadClicked = (meetingId: string, questionId: string) => {
        const { questions } = this.state;

        const onSuccess = (response: any) => {
            this.setState({
                questions: questions.map(q => {
                    if (q.id === questionId) {
                        q.read = response.read;
                    }
                    return q;
                }),
            });
        }

        Utils.postRead(meetingId, questionId, onSuccess, error => this.setState({ error }));
    };

    updateDimensions = () => {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;

        this.setState({ windowWidth });
    }

    render() {
        const { questions, error, isLoading } = this.state;
        const { id } = this.props.match.params;
        const fullscreenUrl = '/' + id;

        const shouldDisplayJoinAtColumn = this.state.windowWidth > 400;
        const questionsComponents = questions.filter(question => !question.read)
                                             .sort((q1, q2) => q2.votes - q1.votes)
                                             .map(question =>
            <PresentationRow {...question} useSmallText={!shouldDisplayJoinAtColumn} onReadClicked={() => this.onReadClicked(id, question.id)} />
        ).slice(0, 3);

        if (error && questions.length === 0) {
            return <Text>{error.message}</Text>;
        }

        if (isLoading) {
            return <Spinner />;
        }

        const joinAtColumn = <div className="ms-Grid-col ms-sm3">
            <Stack tokens={{ childrenGap: '30px' }}>
                <Image
                    src={logo}
                    width='60%'
                    alt="AMA logo"
                    className="centerImage" />
                <Text className="joinAtText">
                    Join at <b>ama.toubiana.dev <br/> #{id}</b>
                </Text>
                <div className="qrCode">
                    <QRCode value={'https://ama.toubiana.dev/' + id} />
                </div>
            </Stack>
        </div>

        return (
            <Stack>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        {shouldDisplayJoinAtColumn && joinAtColumn}
                        <div className={'ms-Grid-col ms-sm' + (shouldDisplayJoinAtColumn ? '9' : '12')}>
                            <div className="topQuestion">
                                <div className="likesContainer">
                                    <Icon className="topQuestionText topQuestionIcon" iconName="FeedbackRequestSolid" />
                                    <Text className="topQuestionText">Top questions</Text>
                                </div>
                            </div>
                            {questionsComponents}
                        </div>
                    </div>
                </div>
                <Link to={fullscreenUrl}><Icon className="topRightIcon" iconName="ChromeClose" /></Link>
            </Stack>
        );
    }
}

export default PresentationPage;
