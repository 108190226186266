import React from 'react';
import { History } from 'history';
import { Link } from 'react-router-dom';
import { Stack, Text, FontWeights, TextField, PrimaryButton } from 'office-ui-fabric-react';
import './../styles/CreatePage.css';
import logo from './../images/logo.png';

const boldStyle = { root: { fontWeight: FontWeights.semibold } };

interface ICreatePageProps {
    history: History
}
interface ICreatePageState {
    meetingId: string,
    recentlyAccessedMeetings: string[]
}

class CreatePage extends React.Component<ICreatePageProps, ICreatePageState> {
    handleChange = (event: any) => {
        this.setState({
            meetingId: event.target.value
        });
    }
    handleClick = (event: any) => {
        if (this.state.meetingId === "") {
            return;
        }
        // add new meeting but only keep 5 most recent
        var meetings = Array.from(new Set([this.state.meetingId, ...this.state.recentlyAccessedMeetings])).slice(0, 5);
        this.setState({
            recentlyAccessedMeetings: meetings,
        });
        localStorage.setItem('recentlyAccessedMeetings', JSON.stringify(meetings));
        this.props.history.push('/' + this.state.meetingId);
    }
    onKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            this.handleClick(event);
        }
    }
    componentDidMount() {
        document.title = 'Ask Me Anything'
    }

    constructor(props: ICreatePageProps) {
        super(props);
        var recentlyAccessedMeetings = localStorage.getItem('recentlyAccessedMeetings') || "[]";
        this.state = {
            meetingId: "",
            recentlyAccessedMeetings: JSON.parse(recentlyAccessedMeetings),
        };
    }

    render() {
        const recentMeetingList = this.state.recentlyAccessedMeetings.map(meetingName =>
            <li key={meetingName}><Link to={'/' + meetingName}><div className="ms-depth-8 recentMeetings"><Text>{meetingName}</Text></div></Link></li>
        );

        return (
            <div className="createPage">
                <Stack
                    verticalAlign="center"
                    verticalFill
                    styles={{
                        root: {
                            maxWidth: '1000px',
                            margin: 'auto',
                            height: 'auto',
                            padding: '0px 20px',
                            textAlign: 'center',
                            color: '#605e5c',
                        }
                    }}
                    gap={40}
                >
                    <Stack gap={20}>
                        <img src={logo} alt="Logo" style={{ width: '100px', margin: '0 auto' }} />
                        <Text
                            variant="xxLargePlus"
                            styles={boldStyle}>
                            AskMeAnything
                        </Text>
                        <Text variant="large">
                            The new way to handle question and response during meetings. <br />
                            <b>Join</b> a meeting by entering the meeting code below.
                        </Text>
                    </Stack>
                    <TextField
                        value={this.state.meetingId}
                        onChange={this.handleChange}
                        onKeyDown={this.onKeyDown}
                        placeholder="Enter meeting code"
                        styles={{fieldGroup: { height: '45px', borderColor: '#605e5c', borderRadius: "6px" } }}
                    />
                    <PrimaryButton onClick={this.handleClick} styles={{ root: { height: '45px', borderRadius: '6px' } }} text="Join" />
                    <ul>
                        {recentMeetingList}
                    </ul>
                </Stack>
            </div>
        );
    }
};

export default CreatePage;
