import React from 'react';
import { Text } from 'office-ui-fabric-react';
import { ToggleLikeButton } from './ToggleLikeButton';

import './../styles/QuestionPage.css'


interface IQuestionRowProps {
    id: string;
    question: string;
    votes: number;
    meetingId: string;
    liked: boolean;
    read: boolean;
    onLikeClicked: (meetingId: string, questionId: string, isLiked: boolean) => void;
}

const QuestionRow: React.FunctionComponent<IQuestionRowProps> = (props) => {
    const { meetingId, id, question, votes, liked, read, onLikeClicked } = props;

    return (
            <div className={'ms-depth-8 questionContainer' + (read ? ' questionRead' : '')}>
                <Text variant="medium">
                    {question}
                </Text>
                <div className="likesContainer">
                    <ToggleLikeButton id={id} meetingId={meetingId} liked={liked} onLikeClicked={onLikeClicked} />
                    <div className="likesItem">
                        <Text>
                            {votes}
                        </Text>
                    </div>
                </div>
            </div>
    );
};

export default QuestionRow;
