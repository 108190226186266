import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Stack, Text, TextField, PrimaryButton, Spinner, FontWeights, Icon, SpinnerSize } from 'office-ui-fabric-react';
import QuestionRow from '../Components/QuestionRow'
import './../styles/QuestionPage.css';
import Utils from '../utilities/apiUtilities';

const boldStyle = {
    root: {
        fontWeight: FontWeights.semibold,
        marginBottom: "20px",
        wordBreak: 'break-all',
    }
};

interface MatchParams {
    id: string;
}

interface IQuestionsPageProps extends RouteComponentProps<MatchParams> {
}

interface Question {
    id: string;
    question: string;
    votes: number;
    createdAt: string;
    read: boolean;
}

interface IQuestionsPageState {
    isLoading: boolean;
    questions: Array<Question>
    error: Error | null
    questionInput: string
    likedQuestions: { [id: string]: boolean }
    meetingId: string
}

class QuestionsPage extends React.Component<IQuestionsPageProps, IQuestionsPageState> {

    intervalId: NodeJS.Timeout | null;

    constructor(props: IQuestionsPageProps) {
        super(props);

        this.state = {
            questions: [],
            isLoading: false,
            error: null,
            questionInput: "",
            likedQuestions: {},
            meetingId: props.match.params.id
        };
        this.intervalId = null;
    }

    componentDidMount() {
        document.title = 'AMA #' + this.state.meetingId
        this.setState({ isLoading: true });
        this.refreshQuestions();
        this.intervalId = setInterval(this.refreshQuestions.bind(this), 10000);
    }

    componentWillUnmount() {
        if (this.intervalId != null) {
            clearInterval(this.intervalId);
        }
    }

    onInputChange = (event: any) => {
        this.setState({
            questionInput: event.target.value
        });
    }

    keyPress = (event: any) => {
        if (event.keyCode === 13) {
            this.onSubmitQuestion();
        }
    }

    refreshQuestions() {
        const { id } = this.props.match.params;

        const onSuccess = (response: { questions: Question[] }) => this.setState({ questions: response.questions, isLoading: false, error: null });

        Utils.GetQuestions(id, onSuccess, error => this.setState({ error, isLoading: false }));
    }

    onSubmitQuestion = () => {
        const { id } = this.props.match.params;
        const { questions, questionInput } = this.state;

        Utils.postQuestion(id, questionInput, response => this.setState({ questions: [response, ...questions], questionInput: '', error: null }), error => this.setState({ error }))
    }

    onLikeClicked = (meetingId: string, questionId: string, isLiked: boolean) => {
        const { questions, likedQuestions } = this.state;

        const onSuccess = (response: any) => {
            const newQuestions = questions.map(q => {
                if (q.id === questionId) {
                    q.votes = response.votes;
                }
                return q;
            });

            const newLikedQuestions = { ...likedQuestions };
            newLikedQuestions[questionId] = !isLiked;
            this.setState({ ...this.state, questions: newQuestions, likedQuestions: newLikedQuestions });
        }

        Utils.postLike(meetingId, questionId, isLiked, onSuccess, error => this.setState({ error }));
    };

    render() {
        const { id } = this.props.match.params;
        const { questions, error, isLoading, questionInput, likedQuestions } = this.state;
        const fullscreenUrl = this.props.location.pathname + "/presentation";

        const questionsComponents = questions.map(question => <QuestionRow {...question} meetingId={id} liked={likedQuestions[question.id]} onLikeClicked={this.onLikeClicked} />);

        if (error && questions.length === 0) {
            return <Text>{error.message}</Text>;
        }

        return (
            <div className="questionPage">
                {error !== null &&
                    <Text>{error.message}</Text>
                }
                <Stack
                    horizontalAlign="center"
                    verticalAlign="center"
                    verticalFill
                    styles={{
                        root: {
                            maxWidth: '1000px',
                            margin: 'auto',
                            height: 'auto',
                            padding: '0px 20px',
                            textAlign: 'center',
                            color: '#605e5c',
                        }
                    }}
                    gap={15}>
                    <Stack
                        gap={10}
                    >
                        <Link to="/"><Icon className="topLeftIcon" iconName="Back" /></Link>
                        <Link to={fullscreenUrl}><Icon className="topRightIcon" iconName="ChromeFullScreen" /></Link>

                        <Text
                            variant="mega"
                            styles={boldStyle}>
                            AMA {id}
                        </Text>
                        <TextField
                            value={questionInput}
                            onChange={this.onInputChange}
                            onKeyDown={this.keyPress}
                            placeholder="Enter your question"
                            styles={{
                                root: {
                                    height: '45px',
                                    borderColor: '#605e5c',
                                    borderRadius: '6px',
                                    width: '100%',
                                }
                            }}
                            />
                        <PrimaryButton onClick={this.onSubmitQuestion} styles={{
                            root: {
                                height: '45px',
                                borderRadius: '6px',
                                width: '100%',
                            }
                        }} text="Submit" />
                    </Stack>

                    <div className='questionListContainer'>
                        {isLoading ? <Spinner size={SpinnerSize.large}/> : questionsComponents}
                    </div>
                </Stack>
            </div>
        );
    }
}

export default QuestionsPage;
